import EmblaCarousel from "embla-carousel";
import EmblaCarouselClassNames from "embla-carousel-class-names";

// Hero Section
// Popup Card animation
// const array = [
// 	{
// 		logo: "/features/images/colossyan-logo.jpg",
// 		nameBrand: "Colossyan AI Creator",
// 		desc: "Colossyan AI video generator make videos in less than 5 minutes.",
// 	},
// 	{
// 		logo: "/features/images/copymatic-logo.jpg",
// 		nameBrand: "Copymatic AI Writer",
// 		desc: "One of the best AI tool, it helps to generate high quality content.",
// 	},
// 	{
// 		logo: "/features/images/play-ht-logo.jpg",
// 		nameBrand: "Play.ht AI Voice-Over",
// 		desc: "PlayHT allows anyone to generate truly human-like speech.",
// 	},
// ];
//
// const logoElement = document.querySelector(".hero-section__rating-card--logo");
// const nameElement = document.querySelector(".hero-section__rating-card--name");
// const descElement = document.querySelector(".hero-section__rating-card--desc");

// let index = 0;

// function displayNextItem() {
// 	const { logo, nameBrand, desc } = array[index];
// 	logoElement.src = logo;
// 	nameElement.textContent = nameBrand;
// 	descElement.textContent = desc;
//
// 	index = (index + 1) % array.length;
// }

// Initial display
// displayNextItem();

// Loop through and display items every 3 seconds
// setInterval(displayNextItem, 3000);

// AI Tools Section
const emblaNode = document.querySelector(".ai-tools-section__embla");
const emblaViewportNode = emblaNode.querySelector(
	".ai-tools-section__embla--viewport",
);
const emblaDotsNode = emblaNode.querySelector(".ai-tools-section__embla--dots");
const options = {
	align: "start",
	slidesToScroll: 1,
	skipSnaps: true,
	containScroll: "trimSnaps",
	dragFree: false,
	breakpoints: {
		"not all and (min-width: 768px)": { align: "center", loop: true },
	},
};
const plugins = [EmblaCarouselClassNames()];
const emblaApi = EmblaCarousel(emblaViewportNode, options, plugins);

const addDotBtnsAndClickHandlers = (emblaApi, dotsNode) => {
	let dotNodes = [];

	const addDotBtnsWithClickHandlers = () => {
		dotsNode.innerHTML = emblaApi
			.scrollSnapList()
			.map(
				() =>
					'<button class="ai-tools-section__embla--dot" type="button"></button>',
			)
			.join("");

		const scrollTo = (index) => {
			emblaApi.scrollTo(index);
		};

		dotNodes = [...dotsNode.querySelectorAll(".ai-tools-section__embla--dot")];

		for (const [index, dotNode] of dotNodes.entries()) {
			dotNode.addEventListener("click", () => scrollTo(index), false);
		}
	};

	const toggleDotBtnsActive = () => {
		const previous = emblaApi.previousScrollSnap();
		const selected = emblaApi.selectedScrollSnap();

		dotNodes[previous].classList.remove(
			"ai-tools-section__embla--dot-selected",
		);
		dotNodes[selected].classList.add("ai-tools-section__embla--dot-selected");
	};

	emblaApi
		.on("init", addDotBtnsWithClickHandlers)
		.on("reInit", addDotBtnsWithClickHandlers)
		.on("init", toggleDotBtnsActive)
		.on("reInit", toggleDotBtnsActive)
		.on("select", toggleDotBtnsActive);

	return () => {
		dotsNode.innerHTML = "";
	};
};

const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
	emblaApi,
	emblaDotsNode,
);

emblaApi.on("destroy", removeDotBtnsAndClickHandlers);
